import { useDispatch, useSelector } from "react-redux";
import { SelectCoin } from "../../../store/slice/coin";
import DashboardLayout from "../../../Layout/DashBoardLayout";
import { FormInput } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { useAddress } from "../../../Hooks/otherHooks";
import { useEffect } from "react";
import { getCoin } from "../../../store/service/common";
import { AppDispatch } from "../../../store";
export default function Address() {
  const { data } = useSelector(SelectCoin);
  const { address } = useAddress(data);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const init = async () => {
      try {
        await dispatch(getCoin());
      } catch (error) {
        console.log(error);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <div className=" text-[20px] font-bold ">Address</div>
      <form onSubmit={address.handleSubmit}>
        <div className=" mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
          <FormInput
            borderShown={false}
            name="btc"
            className=" rounded-md  bg-red-00 border-2 "
            label="Bitcoin"
            type="text"
            error={address.errors.btc!}
            onBlur={address.handleBlur}
            onChange={address.handleChange}
            value={address.values.btc}
          />

          <FormInput
            borderShown={false}
            name="ethereum"
            className=" rounded-md  bg-red-00 border-2 "
            label="Ethereum"
            type="text"
            error={address.errors.ethereum!}
            onBlur={address.handleBlur}
            onChange={address.handleChange}
            value={address.values.ethereum}
          />

          <FormInput
            borderShown={false}
            name="usdt"
            className=" rounded-md  bg-red-00 border-2 "
            label="Usdt"
            type="text"
            error={address.errors.usdt!}
            onBlur={address.handleBlur}
            onChange={address.handleChange}
            value={address.values.usdt}
          />

          <FormInput
            borderShown={false}
            name="xrp"
            className=" rounded-md  bg-red-00 border-2 "
            type="text"
            label="XRP"
            error={address.errors.xrp!}
            onBlur={address.handleBlur}
            onChange={address.handleChange}
            value={address.values.xrp}
          />
        </div>
        <div className="  mt-10 w-full gap-5 bg-red-00 flex justify-end ">
          <Button
            loading={address.isSubmitting}
            className="py-3 w-full md:w-[200px] px-10 bg-main text-white rounded-lg"
            type="submit"
          >
            Update
          </Button>
        </div>
      </form>
    </DashboardLayout>
  );
}
